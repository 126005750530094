import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"

import { Link } from "gatsby"

const IndexPage = () => (
    <Layout>
        <h2>Algo deu errado</h2>
        <div className="col-md-6 col-12">
            <p>
                Não conseguimos processar sua solicitação. Tente novamente ou entre em contato com nosso time de suporte.
      </p>

            <nav>
                <Link
                    style={{ fontWeight: 500, fontSize: "0.8rem", color: "limegreen" }}
                    to={`/`}
                >
                    Voltar
                </Link>
            </nav>
        </div>
    </Layout>
)

export default IndexPage
